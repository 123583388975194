
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { RichTextElement } from 'fsxa-api'

@Component({
  name: 'KeyMessage',
  components: {
    DesignLogo: () => import('../svgs/DesignLogo.vue'),
    FSXARichText: async () => (await import('fsxa-pattern-library')).FSXARichText,
  },
})
export default class KeyMessage extends Vue {
  @Prop({ required: true }) messages! : RichTextElement[]
}
